<template>
  <div class="dashboard1 p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <div class="intro-section-colour-block">
          <div class="row">
            <div class="user-dementienetwerk-section">
              <p class="user-dementienetwerk" v-if="userInfo.dementienetwerknaam">U bent ingelogd op <span
                  class="user-dementienetwerk-name">{{ userInfo.dementienetwerknaam }}</span> netwerk</p>
            </div>

            <p v-if="Object.keys(this.userInfo).length == 0" v-bind:style="{ color: 'red' }"> U hebt geen toegang tot
              deze
              pagina. </p>
            <div v-if="Object.keys(this.userInfo).length != 0">
              <h2 class="intro-section-title">Menu</h2>
              <p class="intro-section-text">Het menu hieronder biedt de netwerkcoördinator verschillende opties:</p>
              <li class="intro-section-text"> Aanmaken deelnemers netwerkanalyse: hier kunt u deelnemers toevoegen en
                verwijderen aan de netwerkanalyse. </li>
              <li class="intro-section-text"> Netwerkanalyse resultaten: hier kunt u de resultaten van de netwerkanalyse
                bekijken.</li>
              <li class="intro-section-text"> Quickscan invullen: hier kunt u de quickscan zelf invullen.</li>
              <li class="intro-section-text"> Quickscan resultaten over tijd: hier kunt u de resultaten van de quickscan
                over tijd bekijken.</li>
              <li class="intro-section-text"> Netwerken in Kaart vragenlijst invullen: hier kunt u de netwerken in kaart
                vragenlijst zelf invullen.</li>
            </div>
            <p class="intro-section-text">
            </p>
          </div>
        </div>
        <div class="intro-section-cc"></div>
      </div>
    </div>
    <div class="d-flex justify-content-around button-div" tabindex="0">
      <button class="button" @click="navigateToGebruikersbeheer" autofocus>Beheer deelnemers netwerkanalyse</button>
      <button class="button" @click="navigateToResultatenRespondenten">Netwerkanalyse resultaten</button>
      <button class="button" @click="navigateToQuickscan">Quickscan invullen</button>
      <button class="button" @click="navigateToResultatenQuickscanOverTijd">Quickscan resultaten over tijd</button>
      <button class="button" @click="navigateToNetwerkInKaart">Netwerken in Kaart invullen</button>
      <button class="button" @click="navigateToNetwerkInKaartPers">Netwerken in Kaart Eigen Resultaten</button>

      <button v-if="this.userInfo.is_staff === true" class="button" @click="downloadExcel" autofocus>Download Netwerken in Kaart Resultaten</button>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Menu',
  },
  computed: {
    ...mapGetters({ userInfo: 'userInfo' })
  },
  // mounted() {
  //   console.log(this.userInfo)
  // },
  methods: {
    navigateToGebruikersbeheer() {
      // navigate to the new page using the `this.$router` object
      this.$router.push('/zorgstandaard/netwerkcoordinator/gebruikersbeheer')
    },
    navigateToResultatenRespondenten() {
      // navigate to the new page using the `this.$router` object
      this.$router.push('/zorgstandaard/netwerkcoordinator/resultaten-netwerkanalyse')
    },
    navigateToQuickscan() {
      // navigate to the new page using the `this.$router` object
      this.$router.push('/zorgstandaard/netwerkcoordinator/quickscan')
    },
    navigateToResultatenQuickscanOverTijd() {
      // navigate to the new page using the `this.$router` object
      this.$router.push('/zorgstandaard/netwerkcoordinator/resultaten-quickscan-over-tijd')
    },
    navigateToNetwerkInKaart() {
      // navigate to the new page using the `this.$router` object
      this.$router.push('/zorgstandaard/netwerkcoordinator/netwerkinkaart')
    },
    navigateToNetwerkInKaartPers() {
      // navigate to the new page using the `this.$router` object
      this.$router.push('/zorgstandaard/netwerkcoordinator/resultaten-netwerkinkaartpers')
    },
    // For downloading Excel
    async downloadExcel() {
      try {
        const response = await this.$http.get(`api/netwerkcoordinatorsnetwerkinkaart/get_answers_for_download/`, { responseType: 'blob' });
        this.handleExcelDownload(response.data);
      } catch (error) {
        console.error('Error downloading Excel:', error);
      }
    },
    handleExcelDownload(data) {
      console.log(data);
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      // Get the current date
      const date = new Date();
      const dateStr = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      link.download = `Netwerken in Kaart Resultaten ${dateStr}.xlsx`;
      link.click();
    },
  }
}
</script>

<style scoped>
.selection {
  max-height: 100vh;
  padding: 5rem;
  margin: 0 auto;
}

.title {

  color: var(--color-1);
}

.selection .container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.button-div {
  margin-top: 5rem;
}

.button {
  align-items: center;
  background-color: var(--color-1);
  border: 2px solid var(--color-1);
  border-radius: 8px;
  box-sizing: border-box;
  color: var(--color-2);
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:after {
  background-color: var(--color-6);
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.button:hover:after {
  transform: translate(0, 0);
}

.button:active {
  background-color: var(--color-6);
  outline: 0;
}

.button:focus {
  outline: none !important;
  box-shadow: none !important;
  background: #e8f0fe;
  color: var(--color-1);
  border: 2px rgb(20, 66, 115) solid !important;
}

.button:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-56 {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 1024px) {
  body {
    overflow-x: hidden;
  }

  @-webkit-keyframes slideIn {
    from {
      left: -100%;
      opacity: 0;
    }

    to {
      left: 0;
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      left: -100%;
      opacity: 0;
    }

    to {
      left: 0;
      opacity: 1;
    }
  }

  .selection {
    float: none;
    margin: 0 auto;
    width: 100%;
  }

  .selection .container {
    -webkit-animation: slideIn 0.8s ease-in-out forwards;
    animation: slideIn 0.8s ease-in-out forwards;
    width: 85%;
    float: none;
  }
}


.info {
  font-style: italic;
  margin: 2rem 0 1rem 0;
}

.list {
  font-style: italic;
}

.intro-section-colour-block {
  background: #f2f2f2;
  width: 100%;
  padding: 5% 8% 8% 5%;
  color: #fff;
  position: relative;
  border: 5px solid #f2f2f2;
  border-radius: 15px;
}

.intro-section-cc {
  width: 100%;
  height: 100px;
  position: relative;
  left: 0px;
  background: linear-gradient(to right bottom, #f2f2f2 49%, #f2f2f2 50%),
    linear-gradient(-50deg, #ffffff 16px, #000 0);
  margin-top: -100px;
  border-radius: 15px;
}

.intro-section-title {
  font-weight: bold;
  color: var(--color-10);
  margin-bottom: 2rem;
}

.intro-section-text {
  color: var(--color-10);
}

.user-dementienetwerk-section {
  border-radius: 15px;
  float: right;
}

.user-dementienetwerk {
  text-align: right;
  color: var(--color-10);
}

.user-dementienetwerk-name {
  font-size: 20px;
  color: var(--color-1);
  font-weight: bold;
}
</style>